<template>
  <div class="static-table">
    <el-table class="table" ref="multipleTable" highlight-current-row border :data="query.data" :select="selects"
      size="small" @sort-change="sortHandler" @current-change="currHandler" @select="selectHandler"
      @selection-change="selectHandler">
      <template v-for="(col, index) in query.columns" :key="index" :prop="col.prop" :label="col.label">
        <el-table-column v-if="(col.type || '') === 'selection'" type="selection" :width="col.width || 55"
          align="center" />

        <el-table-column v-else-if="(col.type || '') === 'index'" :label="col.label" width="50" align="center">
          <template #default="scope">
            <span>{{ scope.$index + (query.page - 1) * query.rows + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column v-else-if="(col.type || '') === 'template'" :prop="col.prop" :label="col.label"
          :sortable="col.sortable" :width="col.width || 0" :show-overflow-tooltip="col.showOverflowTooltip || false">
          <template #default="scope">
            <slot :name="col.prop" :row="scope.row"></slot>
          </template>
        </el-table-column>

        <el-table-column v-else-if="(col.type || '') === 'toolbar'" :label="col.label" :width="col.width || 0"
          fixed="right" align="center">
          <template #default="scope">
            <slot name="toolbar" :row="scope.row" :id="scope.row.id"></slot>
          </template>
        </el-table-column>

        <el-table-column v-else="(col.type || '') === ''" :prop="col.prop" :label="col.label" :sortable="col.sortable"
          :width="col.width || 0" :align="col.align || 'left'"
          :show-overflow-tooltip="col.showOverflowTooltip || false">
        </el-table-column>
      </template>
    </el-table>
    <el-pagination class="pagination" :currentPage="query.page" :page-size="query.rows || query.size || 10"
      :page-sizes="[10, 20, 50, 100, 200, 500]" layout="total, sizes, prev, pager, next, jumper"
      :total="query.records || 0" background @size-change="pageSizeHandler" @current-change="pageChangHandler" />
  </div>
</template>
<script>
export default {
  components: {},
  name: "BasicTable",
  props: {
    query: { type: Object, required: true },
    selects: { type: Object, required: false },
    //pageHandler: { type: Function, required: false }, //分页索引
  },
  emits: ["handler"],
  data() {
    return {
      //pager: { page: 1, rows: 10, records: 0 }
      chooseRow: null,
      selectIds: null,   //当页勾选的数据
    };
  },
  methods: {
    sortHandler: function (order) {
      this.query.sidx = order.prop;
      this.query.sord = order.order;
      this.$emit('handler', this.query);
    },
    pageSizeHandler: function (rows) {
      this.query.rows = rows;
      this.$emit('handler', this.query);
    },
    pageChangHandler: function (page) {
      this.query.page = page;
      this.$emit('handler', this.query);
    },

    currHandler: function (row) {
      console.log('currentHandler', row);
      this.chooseRow = row;
      this.query.current = row;
      //this.$emit('currentHandler', this.query);
    },
    selectHandler: function (rows) {  //勾选发生变动的时候
      //if(!this.selectIds) return;
      //console.log('selectHandler this.selectRows', this.selectRows);
      //console.log('selectHandler all rows',this.$refs.multipleTable.data);
      //console.log('selectHandler start', this.selectRows);

      //this.selectRows = rows;
      //console.log('selectHandler rows', rows)

      //先循环当前页面行数据(不管有没有选中)
      let pageRows = this.$refs.multipleTable.data;
      pageRows.forEach(item => {
        let checked = rows.indexOf(item) >= 0; //是否勾选了该行
        let existed = this.selectIds.indexOf(item.id) >= 0; //勾选总数据中是否已存在

        //console.log('selectHandler name rows',item.realName, rows);

        //如果勾选上了该行，且总勾选中不存在就添加
        if (checked && !existed) {
          this.selectIds.push(item.id);
        }

        //如果取消勾选了该行，且总勾选中存在就删除
        if (!checked && existed) {
          this.selectIds = this.selectIds.filter(q => q != item.id);
        }
      });
      //console.log('selectHandler result', this.selectIds);

      //通过当前行的数据，对比勾选上的数据，得到未勾选的数据。去更新整个数据
      //this.selectRows = rows;
      //this.query.selects = rows;
      //this.$emit('handler', this.query);
    },
  },
  mounted() {
    //console.log('init', this.query)
  },
  watch: {
    query: function (newValue, oldValue) {
      //console.log('basic-table-watch oldValue.newValue', oldValue,newValue);
      let that = this;
      // if (newValue && newValue.selects)
      //   setTimeout(() => {
      //     newValue.selects.forEach(item => {
      //       console.log('that.$refs.multipleTable',that.$refs.multipleTable);
      //       that.$refs.multipleTable.toggleRowSelection(item);
      //     })
      //   });

      //console.log('init selects', this.selectRows)

      //如果设置了selects表示需要对checkedRows进行设置  
      //oldValue && !oldValue.selects &&
      if (this.selectIds) {
        this.selectIds.forEach(item => { // checkedData为已选数据
          this.$nextTick(() => {
            this.query.data.find(obj => { // userData 表单数据
              if (item === obj.id) {
                this.$refs.multipleTable.toggleRowSelection(obj, true)
                //console.log('toggleRowSelection', obj.realName);
              }
            })
          })
        })
      }

    },
    selects: function (newValue, oldValue) {
      //console.log('basic-table-selects oldValue.newValue', oldValue, newValue);
      if (newValue && this.selectIds == null) {
        this.selectIds = newValue;
        console.log('basic-table-selects set success', this.selectIds.length);
      }
    }
  }
};
</script>
<style scoped></style>